.showcase {
  background-image: url("../../assets/img/showcase.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.product-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.product-section-column {
  font-family: Montserrat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 35px;
  background-color: rgba(255, 252, 244, 0.592);
  margin-bottom: 20px; /* 下邊距 */
  border-radius: 10px; /* 圓角邊框 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 陰影效果 */
  text-align: left; /* 文本左對齊 */

  .productdiv {
    width: 45%;
  }

  img {
    width: 30%;
    object-fit: contain;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  h2 {
    font-size: 24px; /* 標題字體大小 */
    font-weight: bold; /* 粗體 */
    margin-bottom: 10px; /* 標題下邊距 */
  }

  p {
    font-size: 16px; /* 段落字體大小 */
    font-weight: 600;
    line-height: 1.5; /* 行高 */
    color: #333; /* 文本顏色 */
  }
}
.product-section {
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 35px;
  background-color: rgba(255, 252, 244, 0.592);
  margin-bottom: 20px; /* 下邊距 */
  border-radius: 10px; /* 圓角邊框 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 陰影效果 */
  text-align: left; /* 文本左對齊 */

  .productdiv {
    width: 45%;
  }

  img {
    width: 30%;
    object-fit: contain;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  h2 {
    font-size: 24px; /* 標題字體大小 */
    font-weight: bold; /* 粗體 */
    margin-bottom: 10px; /* 標題下邊距 */
  }

  p {
    font-size: 16px; /* 段落字體大小 */
    font-weight: 600;
    line-height: 1.5; /* 行高 */
    color: #333; /* 文本顏色 */
  }
}

@media only screen and (max-width: 768px) {
  .product-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    gap: 40px;
    .productdiv {
      width: 65%;
    }
    img {
      width: 80%;
    }
  }
}
