* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
body {
  font-family: "Nunito", sans-serif;
  padding: 0;
  margin: 0;
  cursor: default;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
button {
  text-transform: capitalize !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(152, 149, 149);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: #a2d5f2;
  background: #b6b6b6;
}
