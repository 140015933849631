:root {
  --extrasmallfont: 11px;
  --smallfont: 13px;
  --mediumfont: 15px;
  --largefont: 21px;
}
.marqueenamelabel {
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
  font-size: var(--smallfont);
  text-align: center;
}
.marqueename {
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
  font-size: var(--smallfont);
  text-align: center;
  color: red;
}
.timeplayed {
  display: flex;
  text-align: center;
  cursor: default;
  user-select: none;
}
.timeplayednotcomplete {
  display: flex;
  text-align: center;
  cursor: default;
  user-select: none;
}
.game-name {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: var(--largefont);
  text-align: center;
  cursor: default;
  user-select: none;
  margin: 10px;
}
.book-name {
  font-family: "Nunito";
  font-weight: 700;
  font-size: var(--smallfont);
  color: gray;
  text-align: center;
  cursor: default;
  user-select: none;
}
.page-name {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: var(--smallfont);
  color: black;
  cursor: default;
  user-select: none;
}

.quizlabel {
  font-family: "Nunito";
  font-weight: 600;
  font-size: var(--smallfont);
  text-align: center;
  cursor: default;
  user-select: none;
  margin-right: 10px;
  font-size: 15px;
}

@media only screen and (max-width: 425px) {
  .book-name {
    font-family: "Nunito";
    font-weight: 700;
    font-size: var(--extrasmallfont);
    color: gray;
    text-align: center;
    cursor: default;
    user-select: none;
  }
  .page-name {
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    font-size: var(--extrasmallfont);
    color: black;
    cursor: default;
    user-select: none;
  }
}
