.Search{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 400px;
  left: 50%;
  // position: relative;
  .Search-img{
    img{
      width: 400px;
      position: absolute;
      top: 100%;
      left: 80%;
      transform: translateX(-50%) translateY(-50%);
    }
    .Rotate-img{
      animation-name: spin;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    .Arrow{
      top: 20%;
      left: 90%;
      transform: translateX(-60%) translateY(-50%)  rotate(70deg) ;
      width: 100px;
    }
    @keyframes spin {
      from {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
      }
      to {
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
      }
    }
  }
  h3{
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-15%);
  }
}
.Search-result{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

