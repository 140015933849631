:root {
  --extrasmallfont: 11px;
  --smallfont: 13px;
  --mediumfont: 15px;
  --largefont: 21px;

  --fontWeight: 700;
  --textColor: black;
}
.User {
  font-family: "Nunito", sans-serif;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.User-news {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1px;
  background-color: #f2784b;
  padding: 20px;
  border-radius: 20px;
  font-size: var(--mediumfont); /* Smaller font size */
  font-weight: var(--fontWeight);
  color: #000000; /* Warm text color */
}

.User-container {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 10px;
  gap: 15px;
  width: 600px;
}

.User-left,
.User-right {
  color: var(--textColor);
}

.User-profile-details {
  background-color: #bad98b;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.User-profile-title {
  font-size: var(--largefont);
  font-weight: bold;
  margin-bottom: 8px;
}

.title-text {
  font-size: 20px; /* Smaller font size */
  font-weight: 900;
  color: var(--textColor); /* Warm text color */
  margin-bottom: 8px;
}

.user-info-container {
  width: 100%;
  border-radius: 8px;
  padding: 8px;
}

.user-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 6px 0;
  border-bottom: 1px solid #ffffff; /* Warm border color */
}

.user-info-label {
  font-weight: var(--fontWeight);
  font-size: var(--mediumfont);
  color: var(--textColor); /* Warm text color */
}

.second-title-text {
  font-size: var(--mediumfont); /* Smaller font size */
  font-weight: var(--fontWeight);
  color: var(--textColor); /* Warm text color */
}

.User-passed-items {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.User-passed-items-title {
  font-size: var(--largefont); /* Smaller font size */
  font-weight: var(--fontWeight);
  color: var(--textColor); /* Warm text color */
  margin-bottom: 8px;
}

.User-passed-items-list {
  width: 100%;
  list-style: none;
}

// .User-passed-items-ul {
//   padding: 0;
//   margin: 0;
// }

.User-passed-items-li {
  margin-bottom: 6px;
}

.User-passed-items-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.User-passed-items-key {
  font-size: var(--mediumfont);
  font-weight: var(--fontWeight);
  color: var(--textColor); /* Warm text color */
}

.User-passed-items-playcount {
  font-size: var(--mediumfont);
  font-weight: var(--fontWeight);
  color: var(--textColor); /* Warm text color */
}

.User-passed-items-none {
  font-size: var(--mediumfont);
  color: var(--textColor); /* Warm text color */
  text-align: center;
}

@media (max-width: 768px) {
  .User-container {
    width: 100%;
    // flex-direction: column;
  }

  .User-left,
  .User-right {
    width: 100%;
  }
}
