.Developer {
  font-family: "Nunito", sans-serif;
  background-color: #fcf8ec;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10vh;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  .Developer-profile {
    display: flex;
    background-color: #fcf8ec;
    align-items: center;
    .Developer-profileCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5vh;
      .Developer-title {
        color: #0d5d82;
        font-size: 20px;
        font-weight: 600;
        padding: 15px;
      }
      .Developer-img {
        border-radius: 20px;
        padding: 2px;
        width: 250px;
        box-shadow: 0 0 20px 3px rgba(153, 153, 153, 0.681);
      }
    }
    .Developer-profileDetails {
      background-color: #d2e2ff;
      font-weight: 600;
      font-size: 20px;
      padding: 15px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      .Developer-title {
        color: #0d5d82;
        font-size: 20px;
        font-weight: 800;
      }
      .Developer-text {
        // letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .Developer-profileDetails {
    .Developer-text {
      font-size: 18px;
      // letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  .Developer .Developer-profile {
    display: flex;
    flex-direction: column;
  }
}
