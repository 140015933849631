.Containerfull {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fcf8ec;
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the maximum height */
  z-index: 999;
}
.containernavbar {
  width: 100%;
  position: sticky;
  top: 0;
}
.containerchildren {
  width: 100%;
}
.containermusicplayer {
  width: 100%;
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 970px) {
  .Containerfull {
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the maximum height */
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 999;
  }
}
