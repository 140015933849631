.music-card {
  border-radius: 20px;
  width: 475px;
  padding: 5px;
  margin: 5px;
  // background-color: rgb(255, 255, 255);
  background-color: #fcf8ec;
  // border: solid 5px rgb(255, 227, 227);
  border: solid 2px rgb(255, 227, 227);
  cursor: default;
  user-select: none;

  &.playing {
    background-color: #ffd969; // Background color when music is playing
  }
}
.gamesection {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  height: fit-content;
}
.sharebtn-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  // height: 5px;
  cursor: pointer;

  .sharebtn {
    width: 20px;
  }
}

.playbutton {
  display: flex;
  align-items: center;
  align-content: center;
  // border: solid 1px rgb(255, 0, 0);
  // background-color: antiquewhite;
  border-radius: 50px;
  // padding: 5px;
  cursor: pointer;
}

.gamebutton {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 12px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  border: solid 1px rgb(0, 153, 255);
  background-color: antiquewhite;
  border-radius: 50px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}
.gamebutton > span {
  margin-right: 5px;
}
// .playbutton:hover {
//   // box-shadow: 0 0 15px rgba(0, 132, 255, 0.629);
//   transition: 200ms;
// }
.playicon {
  height: 35px;
  width: 35px;
  color: rgb(249, 42, 27);
  background-color: transparent;
}

.circleicon {
  height: 20px;
  width: 20px;
  color: rgb(0, 132, 255);
}
.musicbanner {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.buttoncontainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.timesplayedcontainer-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 15px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  background-color: antiquewhite;
  border-radius: 50px;
  margin: 10px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;

  // display: flex;
  // align-items: center;
  // justify-content: space-evenly;
  // padding: 10px 0 10px 0;
  // margin: 10px 0 10px 0;
  // background-color: antiquewhite;
  // border-radius: 20px;
}
.timesplayedcontainer-web {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0 10px 0;
  margin: 10px 0 10px 0;
  background-color: antiquewhite;
  border-radius: 20px;
}
.timesplayedlabel {
  font-weight: bolder;
  font-size: 0.8rem;
  text-align: center;
}
.timesplayednumber {
  font-weight: bolder;
  font-size: 0.8rem;
  text-align: center;
  color: red;
}
.complete {
  color: gray;
  font-weight: bolder;
  font-size: 0.8rem;
  text-align: center;
}
.notcomplete {
  color: black;
  font-weight: bolder;
  font-size: 0.8rem;
  text-align: center;
}

.musiccardimage {
  width: 60px;
  height: 60px;
  // margin-left: auto;
  // margin-right: auto;
}

.labelcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.passicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}
@media only screen and (max-width: 475px) {
  // .sharebtn-container {
  //   position: fixed;
  //   top: 10px;
  //   right: 10px;
  // }
  .passicon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
  }
  .musicbanner {
    padding-left: 10px;
  }
  .music-card {
    width: 100vw;
  }
  .musiccardimage {
    width: 50px;
    height: 50px;
  }
  .playbutton {
    padding: 5px;
  }
  .gamebutton {
    font-size: 8px;
    padding: 8px;
    margin: 5px;
  }
  .playicon {
    height: 30px;
    width: 30px;
    color: rgb(249, 42, 27);
  }
}
