.showcasenavbar {
  background-color: rgb(255, 255, 255) !important;
  height: 50px;
  transition: background-color 0.5s ease, height 0.5s ease;
  position: sticky !important;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}
.showcasenavbar.scrolled {
  background-color: rgb(212, 226, 255) !important;
  // background-color: rgba(212, 230, 255, 0.9) !important;
  height: 70px;
}
.showcase-loginbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 800;
  font-size: 17px;
}
.showcase-tryforfreebtn {
  background-color: rgb(255, 101, 101);
  font-weight: 800;
  font-size: 15px;
  border-radius: 15px;
  color: white;
  height: 30px;
}
.showcasenavlink {
  font-weight: 600;
  font-size: 15px;
  color: black;
}
.offcanvas-header {
  padding: 20px 20px;
}

@media only screen and (max-width: 1200px) {
  .showcasenavbar {
    height: fit-content !important;
  }
}
