.Mainnavbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

.navbackground {
  background-color: rgb(255, 255, 255) !important;
  transition: background-color 0.3s ease, height 0.3s ease;
  position: sticky;
  width: 100%;
  height: 10vh;
  align-items: center;
  align-content: center;
}

.navbackground.scrolled {
  background-color: rgb(184, 208, 255) !important;
  // background-color: rgb(212, 226, 255) !important;
  height: 12vh;
}

.offcanvas-body {
  background-color: rgb(255, 255, 255) !important;
}
.offcanvas-body.scrolled {
  background-color: rgb(184, 208, 255) !important;
}

.username {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
}
.icons {
  svg {
    color: rgb(81, 204, 43);
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
.offcanvas-header {
  padding: 20px 20px;
}
// .navcurrentdaycircle {
//   font-family: "Nunito", sans-serif;
//   font-weight: 700;
//   width: 60px;
//   height: 60px;
// }
// .navdailycircletextcomplete {
//   font-family: "Nunito", sans-serif;
//   display: flex;
//   justify-content: center;
//   font-weight: 700;
//   align-items: center;
//   color: #fc3434;
//   font-size: 15px;
// }
// .navdailycircletextnotcomplete {
//   font-family: "Nunito", sans-serif;
//   display: flex;
//   justify-content: center;
//   font-weight: 700;
//   align-items: center;
//   color: #000000;
//   font-size: 15px;
// }
.containerfluid {
  transition: all 0.3s cubic-bezier(0.6, 0.4, 0, 1);
  z-index: 999;
}
.container-fluid {
  padding-right: 0;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
}
.toggle {
  // background-color: rgb(255, 255, 255);
  border: none;
  outline: none;
  font-weight: 700;
}
.navbar-toggler:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.navbar-toggler-icon {
  font-size: 20px;
}

.dropdown-menu {
  width: 250px !important;
  // background-color: #f7edce;
}
.dropdown-toggle {
  color: #000000;
}
.dropdown-toggle:hover {
  color: #f82222;
  // background-color: rgb(212, 212, 212);
  // border-radius: 20px;
}

.navlabel {
  padding: auto;
  color: #2d7dd2;
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.navlink {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 15px;
}
.nav-link {
  display: flex;
  align-items: center;
}

.navlinkscoreboard {
  // padding: auto;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.navlinksetting {
  padding: auto;
  color: rgb(0, 89, 255) !important;
  font-size: 17px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.subnavlink {
  --c: #f6ab28; /* the color */
  background: linear-gradient(90deg, #ffffff 50%, var(--c) 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) 100% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.3s;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.subnavlink:hover {
  --_p: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usernavlink {
  background: linear-gradient(90deg, #ffffff 50%, var(--c) 0)
      calc(100% - var(--_p, 0%)) / 200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) 100% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.3s;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
