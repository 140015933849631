.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;

  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50; // 可根據需要更改顏色
    transition: width 0.3s ease-in-out;
  }
}

.progress-info {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

.Playlist {
  // position: absolute;
  top: 10vh;
  // bottom: 13vh;
  width: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  // background-color: #fcf8ec;
}
.Playlist-container {
  // background-color: #fcf8ec;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.playlisttitle {
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 18px;
  padding: 10px;
  font-weight: 500;
  background-color: #005b7f;
  color: #fff4d5;
  text-align: center;
  justify-content: center;
  font-family: "Nunito", sans-serif;
}

.newfunctionalert {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  background-color: #ff7070;
  color: #000000;
  text-align: center;
  justify-content: center;
  font-family: "Nunito", sans-serif;
}

@media only screen and (max-width: 675px) {
  .newfunctionalert {
    font-size: 13px;
  }
}
