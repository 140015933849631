.footer-player {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: "50px";
}
.rhap_container {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  visibility: visible;
  height: 100px;
}
.marquee {
  flex: none !important;
  min-width: 100%;
  min-height: 100%;
  // overflow: hidden;
  gap: 20px;
  cursor: default;
  user-select: none;
  height: 30px;
}
.rfm-marquee {
  height: 30px;
}
.rhap_progress-section {
  height: 30px;
  justify-content: space-between;
}
.musicnotification {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #000000 !important;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  display: flex;
}
.noInteractionCountWarning {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  color: #000000 !important;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  display: flex;
}

// .Toastify__toast-container {
//   width: fit-content;
// }
.rhap_time {
  font-size: 0.85rem;
  font-weight: 700;
}
.rhap_play-pause-button {
  font-size: 50px;
  width: 50px;
  height: 50px;
}

// 時間軸點點顏色
.rhap_progress-indicator {
  background-color: rgb(255, 0, 0) !important;
}
// 音量點點顏色
.rhap_volume-indicator {
  background-color: rgb(73, 73, 73) !important;
}
// 重複鍵顏色
.rhap_repeat-button {
  color: rgb(255, 0, 0) !important;
}
.rhap_progress-filled {
  background-color: rgb(255, 0, 0) !important;
}
.rhap_volume-button {
  color: rgb(73, 73, 73) !important;
}
.rhap_main-controls > button {
  color: rgb(73, 73, 73) !important;
}
.rhap_additional-controls {
  color: rgb(0, 145, 255) !important;
}
// .nomusicname {
//   font-weight: bolder;
//   font-size: 0.7rem;
//   color: rgb(255, 0, 0);
//   text-align: center;
// }

.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
.music-container {
  width: 500px;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  .music-detail {
    display: flex;
  }
}
// @media only screen and (max-width: 675px) {
//   // .rhap_volume-container {
//   //   display: none;
//   // }
//   .rhap_main-controls {
//     font-size: 50px;
//     flex: 5 0;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
