.solve-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.solve-page {
  width: 400px;
  padding: 30px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    display: block;
    margin-bottom: 20px;
    color: #0077cc;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }
}
