.Trade {
  height: 100vh; // Use viewport height
  display: flex;
  flex-direction: column; // Ensure the content stacks vertically
  background-color: antiquewhite;
  align-items: center;
  justify-content: center;
}

.trade-container {
  flex: 1; // Allow this to grow and fill the available space
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: antiquewhite;
  border-radius: 8px;
  overflow-y: auto; // Add scrollbar if content overflows
}

.userinfo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .info {
    font-weight: 800;
    margin: 10px;
    color: #356cb0;
  }
}

.Tradetitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: #333;
  gap: 20px;
}

.trade-list {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.trade-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.trade-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: #333;
}

.trade-img {
  width: 50px;
  margin-right: 10px;
}

.stock-img {
  width: 50px;
}

.trade-list-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #444;
}

.trade-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  input {
    margin-left: 10px;
    border-radius: 10px;
  }
}

.risk-rate-high {
  background-color: red;
  padding: 8px 10px 8px 10px;
  border-radius: 20px;
  color: white;
  font-weight: 800;
}
.risk-rate-medium {
  background-color: rgb(255, 248, 41);
  padding: 8px 10px 8px 10px;
  border-radius: 20px;
  color: black;
  font-weight: 800;
}
.risk-rate-low {
  background-color: rgb(96, 255, 43);
  padding: 8px 10px 8px 10px;
  border-radius: 20px;
  color: black;
  font-weight: 800;
}
.trade-item-bottom {
  display: flex;
}

.game-rule {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 800;
  color: red;
}

.trade-name,
.trade-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}

.editbutton {
  border: solid 3px black;
  background-color: antiquewhite;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 800;
}

.button-group {
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
}

.savebutton {
  border: solid 3px rgb(0, 0, 0);
  background-color: rgb(91, 198, 255);
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 800;
  color: rgb(0, 0, 0);
}

.cancelbutton {
  border: solid 3px black;
  background-color: rgb(253, 83, 83);
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 800;
}

.news {
  background-color: rgb(238, 72, 72);
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  color: rgb(255, 255, 255);
}

.arrow-up {
  margin-left: 10px;
}
.arrow-down {
  margin-left: 10px;
}

// .loss-profit-calculation {
//   padding: 25px;
//   background-color: antiquewhite;
//   border-radius: 8px;

//   .title {
//     font-size: 24px;
//     font-weight: 800;
//     margin-bottom: 10px;
//     text-align: center;
//     color: #333;
//   }

//   .input-group {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 10px;

//     label {
//       font-size: 16px;
//       font-weight: 700;
//       color: #555;
//       margin-bottom: 5px;
//     }

//     input {
//       padding: 8px;
//       border: 1px solid #ddd;
//       border-radius: 4px;
//       font-size: 16px;
//     }
//   }

//   .summary {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 15px;

//     div {
//       font-size: 18px;
//       font-weight: 700;
//       color: #333;
//       margin-bottom: 5px;
//     }
//   }

//   .profit-message {
//     font-size: 20px;
//     font-weight: bold;
//     text-align: center;
//     color: #2a9d8f;
//   }
// }

.user-stocks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  .stock-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .stock-name {
      font-size: 18px;
      font-weight: 800;
      color: #000000;
    }

    .stock-quantity {
      font-size: 16px;
      font-weight: 800;
      color: #ff5a5a;
    }
  }
}

.order-button {
  border: none;
  background: linear-gradient(90deg, #007bff, #0056b3);
  color: white;
  margin: 10px;
  border-radius: 5px;
  padding: 8px 15px;
  font-weight: 800;

  &:hover {
    background: linear-gradient(90deg, #0056b3, #003d7a);
  }
}
