.logoutcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.logoutbtn {
  gap: 15px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 700;
  border: none;
  color: #ffffff;
  background-color: #f2784b;
  padding: 10px;
  border-radius: 10px;
}
// .logoutbtn:hover {
//   box-shadow: 0 0 15px #d22d2d;
//   // box-shadow: 0 0 15px rgba(0, 0, 0, 0.533);
//   transition: 350ms ease-in-out;
// }
