.trade-track {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tradetrack-title {
  font-size: 24px;
  text-align: center;
  color: #333;
  margin: 20px;
}
.tradetrack-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tradetrack-conclude {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #59a9ff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  font-size: 18px;
  font-weight: 800;
  color: #000000;
}
.tradetrack-ul {
  list-style: none;

  .tradetrack-li {
    background-color: #fff;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
    font-weight: 800;

    .tradetrack-detail {
      margin: 5px 0;
      color: #555;

      &:first-child {
        font-weight: bold;
        color: #007bff; // Highlight for the team name
      }
    }
  }
}
