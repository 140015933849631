.Signup {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/img/signupbackground.webp");
  background-size: cover;
  background-position: center; /* Centers the background image */
  opacity: 0.6;
  z-index: -1; /* Ensures the image stays behind the content */
}
.signupsection {
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 10px;
  gap: 15px;

  span {
    font-weight: 600;
  }
}
.signupinput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;

  input {
    border: solid 2.5px;
    border-radius: 5px;
    padding: 5px;
  }
}

.signupbtn {
  margin: 20px 0px;
  padding: 10px 15px;
  font-size: 18px;
  letter-spacing: 5px;
  border-radius: 10px;
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 600;
  border: 3.5px solid #2d7dd2;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}

.student-table-container {
  height: 70vh; /* Set table height to 50% of viewport height */
  width: 250px;
  overflow-y: scroll; /* Enable vertical scrolling */
}

.student-table {
  width: 100%; /* Make table occupy full width */
  border-collapse: collapse; /* Remove default cell spacing */
}

th,
td {
  padding: 10px; /* Add padding to cells */
  border: 1px solid #ddd; /* Add borders to cells */
}

@media screen and (max-width: 768px) {
  .Signup {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .student-table-container {
    width: 80%;
  }
  .signupsection {
    width: 80%;
  }
}
