.order-page {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #faefef;
  padding: 30px;
  border-radius: 10px;
  .Ordertitle {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    color: #000000;
  }

  button {
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
  }

  .back-button {
    background: linear-gradient(90deg, #007bff, #0056b3);
    color: white;
    font-weight: 800;

    &:hover {
      background: linear-gradient(90deg, #0056b3, #003d7a);
    }
  }

  .buy-button {
    background: linear-gradient(90deg, #28a745, #218838);
    color: white;
    border-radius: 5px;
    font-weight: 800;

    &:hover {
      background: linear-gradient(90deg, #218838, #1e7e34);
    }
  }

  .sell-button {
    background: linear-gradient(90deg, #dc3545, #c82333);
    color: white;
    border-radius: 5px;
    font-weight: 800;

    &:hover {
      background: linear-gradient(90deg, #c82333, #bd2130);
    }
  }

  .buy-button:disabled,
  .sell-button:disabled {
    background-color: #ccc; /* Light gray background */
    color: #ffffff; /* Gray text */
    cursor: not-allowed; /* Show a not-allowed cursor */
    opacity: 0.7; /* Slightly transparent */
    pointer-events: none; /* Prevent clicking */
  }

  .input-group {
    margin-bottom: 10px;
    padding: 10px;
    background: white;
    border-radius: 8px;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .stock-info {
      margin-top: 8px;
      font-size: 14px;
      color: #555;
    }
  }

  .remaining-money {
    margin-top: 10px;
    padding: 5px;
    background: white;
    border-radius: 8px;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 800;
  }

  .input-top {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }

  .input-top-title {
    font-size: 16px;
    font-weight: 600;
    color: #444;
  }

  .input-group:last-child {
    margin-bottom: 0;
  }

  .stock-input {
    width: 100px;
    height: 40px; /* Increase the height to make the arrow bigger */
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    background: #f9f9f9;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }
  /* Remove the arrows from input[type="number"] in WebKit browsers */
  .stock-input::-webkit-outer-spin-button,
  .stock-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Optional: removes margin in WebKit browsers */
  }

  /* Remove the arrows from input[type="number"] in other browsers */
  .stock-input {
    appearance: none;
  }

  .user-stocks-orderpage {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;

    .order-title {
      font-size: 20px;
      font-weight: 700;
      color: #333;
      text-align: center;
    }

    .order-item-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .stock-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 10px;
      border-radius: 10px;

      .order-img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
      }

      .stock-name {
        font-size: 1em;
        font-weight: 800;
        color: #333;
        margin-bottom: 5px;
      }

      .stock-quantity {
        font-size: 0.9em;
        font-weight: 800;
        color: #ff5a5a;
      }
    }
  }
}

.item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  box-shadow: 0px 5px 20px rgb(201, 201, 201);
  border-radius: 20px;
  padding: 10px;
}
.item-title {
  font-size: 16px;
  font-weight: 800;
  color: #0056b3;
}
.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.item {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 800;
}

.total-cost {
  font-weight: 800;
  margin-top: 10px;
}
.error-message {
  color: red;
  font-weight: 800;
  margin-top: 10px;
}
